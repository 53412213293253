import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from "react-redux";

import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { hydrate, render } from "react-dom";

const rootElement = document.getElementById("root");
    if (rootElement.hasChildNodes()) {
      hydrate(<BrowserRouter>
        <ToastContainer />
        <App />
    </BrowserRouter>, rootElement);
    } else {
      render(<BrowserRouter>
        <ToastContainer />
        <App />
    </BrowserRouter>, rootElement);
    }

 
 
    