import React, { Component, Suspense } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-loading-skeleton/dist/skeleton.css";
import { Routes, Route, useSearchParams } from "react-router-dom";
import "./App.css";

const SignThank = React.lazy(() => import("./Home/common/Form/SignThank"));
const OnboardForm = React.lazy(() => import("./Home/common/Form/OnboardForm"));
const Filter = React.lazy(() => import("./Home/pages/filter"));
const PageNotFound = React.lazy(() => import("./Home/pages/PageNotFound"));
const BlogHome = React.lazy(() => import("./Home/blog/BlogHome"));

const RestaurantOnlineOrdering = React.lazy(() =>
  import("./Trysimmer/BlogDetails/RestaurantOnlineOrdering")
);
const SimmerHelpsRestaurantOwners = React.lazy(() =>
  import("./Trysimmer/BlogDetails/SimmerHelpsRestaurantOwners")
);
const FoodOrderingPortal = React.lazy(() =>
  import("./Trysimmer/BlogDetails/FoodOrderingPortal")
);
const RestaurantDeal = React.lazy(() =>
  import("./Trysimmer/BlogDetails/restaurantDeal")
);
const FreeOnlineOrderingSystems = React.lazy(() =>
  import("./Trysimmer/BlogDetails/FreeOnlineOrderingSystems")
);
const QRCodeMenu = React.lazy(() =>
  import("./Trysimmer/BlogDetails/QRCodeMenu")
);
const QrCodePayments = React.lazy(() =>
  import("./Trysimmer/BlogDetails/QrCodePayments")
);
const RestaurantSoftwareSolution = React.lazy(() =>
  import("./Trysimmer/BlogDetails/RestaurantSoftwareSolution")
);
const Enhance = React.lazy(() => import("./Trysimmer/BlogDetails/Enhance"));
const RevolutionizeChatGPTTechnology = React.lazy(() =>
  import("./Trysimmer/BlogDetails/RevolutionizeChatGPTTechnology")
);
const NewHome = React.lazy(() => import("./Trysimmer/NewHome"));
const Solution = React.lazy(() => import("./Trysimmer/Solutions"));
const TermsConditions = React.lazy(() => import("./Trysimmer/TermsConditions"));
const Contact = React.lazy(() => import("./Trysimmer/Contact"));
const About = React.lazy(() => import("./Trysimmer/About"));
const Learn = React.lazy(() => import("./Trysimmer/Learn"));
const Pricing = React.lazy(() => import("./Trysimmer/Pricing"));
const Privacy = React.lazy(() => import("./Trysimmer/Privacy"));
const HomeSimmer = React.lazy(() => import("./Trysimmer/indexHome"));
const HomeSimmerThank = React.lazy(() => import("./Trysimmer/indexThankyou"));
const Customers = React.lazy(() => import("./Trysimmer/Customers"));
const Career = React.lazy(() => import("./Trysimmer/Career"));
const Tutorials = React.lazy(() => import("./Trysimmer/Tutorials"));
const Blog = React.lazy(() => import("./Trysimmer/Blog"));
const Webstories = React.lazy(() => import("./Trysimmer/webstories"));
const Stories = React.lazy(() => import("./Trysimmer/Stories"));
const Sanjose = React.lazy(() => import("./Trysimmer/Sanjose"));
const Sunnyvale = React.lazy(() => import("./Trysimmer/Sunnyvale"));
const Sanfrancisco = React.lazy(() => import("./Trysimmer/Sanfrancisco"));
const Fremont = React.lazy(() => import("./Trysimmer/Fremont"));
const QrcodeSanjose = React.lazy(() => import("./Trysimmer/QrcodeSanjose"));
const QrcodeSunnyvale = React.lazy(() => import("./Trysimmer/QrcodeSunnyvale"));
const QrcodeSanFrancisco = React.lazy(() =>
  import("./Trysimmer/QrcodeSanFrancisco")
);
const QrcodeFremont = React.lazy(() => import("./Trysimmer/QrcodeFremont"));
const RequestDemo = React.lazy(() => import("./Trysimmer/RequestDemo"));

class App extends Component {
  render() {
    return (
      <div className="App">
        <Suspense fallback={<div></div>}>
          <Routes>
            <Route exact path="/" element={<NewHome />} />
            <Route exact path="/about-us" element={<About />} />
            <Route exact path="/solutions" element={<Solution />} />
            <Route exact path="/contact" element={<Contact />} />
            <Route exact path="/learn" element={<Learn />} />
            <Route exact path="/pricing" element={<Pricing />} />
            <Route exact path="/thankyou" element={<HomeSimmerThank />} />
            {/* <Route exact path="/doordash" element={<Filter />} /> */}
            <Route exact path="/res-signup" element={<OnboardForm />} />
            <Route exact path="/res-signup/thankyou" element={<SignThank />} />
            <Route exact path="/privacy" element={<Privacy />} />
            <Route exact path="/ai-order-system" element={<HomeSimmer />} />
            <Route exact path="/request-a-demo" element={<RequestDemo />} />
            <Route
              exact
              path="/terms-conditions"
              element={<TermsConditions />}
            />
            <Route exact path="/blog" element={<Blog />} />
            <Route exact path="/career" element={<Career />} />
            <Route exact path="/tutorials" element={<Tutorials />} />

            <Route exact path="/customers" element={<Customers />} />
            <Route
              exact
              path="/no-more-missed-orders"
              element={<Webstories />}
            />
            <Route exact path="/webstories" element={<Stories />} />
            <Route
              exact
              path="/blog/ramp-up-your-restaurant-online-ordering-system"
              element={<RestaurantOnlineOrdering />}
            />
            <Route
              exact
              path="/blog/benefits-of-owning-an-online-food-ordering-portal"
              element={<FoodOrderingPortal />}
            />
            <Route
              exact
              path="/blog/10-ways-simmer-helps-restaurant-owners"
              element={<SimmerHelpsRestaurantOwners />}
            />
            <Route
              exact
              path="/blog/how-can-restaurant-deal-with-staff-shortage-issues"
              element={<RestaurantDeal />}
            />
            <Route
              exact
              path="/blog/are-free-online-ordering-systems-restaurants-worth"
              element={<FreeOnlineOrderingSystems />}
            />
            <Route
              exact
              path="/blog/12-reasons-to-use-a-qr-code-menu-restaurant"
              element={<QRCodeMenu />}
            />
            <Route
              exact
              path="/blog/understanding-qr-code-payments-types-benefits-and-trends"
              element={<QrCodePayments />}
            />
            <Route
              exact
              path="/blog/simmer-all-in-one-restaurant-software-solution"
              element={<RestaurantSoftwareSolution />}
            />
            <Route
              exact
              path="/blog/enhance-your-customer-experience-with-our-restaurant-software-solutions"
              element={<Enhance />}
            />
            <Route
              exact
              path="/blog/revolutionize-your-pizza-restaurant-with-chatgpt-technology"
              element={<RevolutionizeChatGPTTechnology />}
            />

            <Route
              exact
              path="/restaurant-software-solution-in-sanjose"
              element={<Sanjose />}
            />
            <Route
              exact
              path="/restaurant-software-solution-in-sunnyvale"
              element={<Sunnyvale />}
            />
            <Route
              exact
              path="/restaurant-software-solution-in-san-francisco"
              element={<Sanfrancisco />}
            />
            <Route
              exact
              path="/restaurant-software-solution-in-fremont"
              element={<Fremont />}
            />
            <Route
              exact
              path="/qr-code-ordering-system-in-sanjose"
              element={<QrcodeSanjose />}
            />
            <Route
              exact
              path="/qr-code-ordering-system-in-sunnyvale"
              element={<QrcodeSunnyvale />}
            />
            <Route
              exact
              path="/qr-code-ordering-system-in-san-francisco"
              element={<QrcodeSanFrancisco />}
            />
            <Route
              exact
              path="/qr-code-ordering-system-in-fremont"
              element={<QrcodeFremont />}
            />

            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Suspense>
      </div>
    );
  }
}

function MainApp(props) {
  return (
    <>
      <App {...props} />
    </>
  );
}

export default MainApp;
